var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboardslisting"
  }, [_c('v-row', {
    staticClass: "page-header mb-16 mt-8"
  }, [_c('v-col', [_c('r-page-header', {
    staticClass: "dashboardslisting--pageheader",
    attrs: {
      "label": _vm.$t('dashboards.dashboardsListing.title')
    },
    scopedSlots: _vm._u([{
      key: "end",
      fn: function () {
        return [_c('r-button', {
          attrs: {
            "label": _vm.$t('dashboards.addDashboard.title'),
            "icon": "add"
          },
          on: {
            "click": _vm.onClickAddDashboard
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1), _c('v-row', {
    staticClass: "ma-0 mt-16 pt-8"
  }, [_vm.dashboards.length ? _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('r-error-message', {
    attrs: {
      "errors": [_vm.deleteDashboardError]
    }
  }), _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "end"
    }
  }, [_c('r-search-input', {
    staticClass: "mr-2",
    staticStyle: {
      "width": "300px",
      "flex-grow": "0"
    },
    attrs: {
      "value": _vm.search
    },
    on: {
      "change": _vm.onChangeSearch
    }
  }), _c('r-button', {
    staticStyle: {
      "height": "32px"
    },
    attrs: {
      "outlined": "",
      "label": _vm.$t('global.buttons.delete'),
      "disabled": _vm.disableDeleteButton,
      "loading": _vm.fetchingDeleteDashboard
    },
    on: {
      "click": _vm.onBatchDelete
    }
  })], 1)], 1) : _vm._e(), _vm.search ? _c('v-col', {
    staticClass: "pa-0 dashboardslisting--chiprow",
    attrs: {
      "cols": "12"
    }
  }, [_c('r-chip', {
    attrs: {
      "removable": "",
      "label": _vm.search
    },
    on: {
      "click": function ($event) {
        _vm.search = '';
      }
    }
  })], 1) : _vm._e(), _c('v-col', {
    staticClass: "pa-2 pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_vm.selectedTab === 0 ? _c('single-dashboards-table', {
    attrs: {
      "search": _vm.search
    },
    on: {
      "change:batch-select": _vm.onDashboardsBatchSelection,
      "change:batch-select-all": _vm.onDashboardsSelectAll
    }
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
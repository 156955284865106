import { render, staticRenderFns } from "./SingleDashboardsTable.vue?vue&type=template&id=3312012e&scoped=true&"
import script from "./SingleDashboardsTable.vue?vue&type=script&lang=js&"
export * from "./SingleDashboardsTable.vue?vue&type=script&lang=js&"
import style0 from "./SingleDashboardsTable.vue?vue&type=style&index=0&id=3312012e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3312012e",
  null
  
)

export default component.exports
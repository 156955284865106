export const SINGLE_DASHBOARD_TYPE = {
  'single-product': 'Product',
  'single-category': 'Category',
  'single-tag': 'Tag',
  'single-brand': 'Brand',
  'multi-product': 'Multiple Product',
}

export const SINGLE_DASHBOARD_ICONS = {
  'single-product': 'book',
  'single-category': 'mdi-view-grid',
  'single-tag': 'mdi-tag',
  'single-brand': 'mdi-alpha-b-circle',
  'multi-product': 'mdi-content-copy',
}
